import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DropdownContext } from './DropdownContext'
import { mapToCssModules, omit, tagPropType } from '../utils'

const propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
  tag: tagPropType,
  header: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  toggle: PropTypes.bool,
  text: PropTypes.bool,
}

const defaultProps = {
  tag: 'button',
  toggle: true,
}

export class DropdownItem extends React.Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
    this.getTabIndex = this.getTabIndex.bind(this)
  }

  getRole() {
    if (this.context.menuRole === 'listbox') {
      return 'option'
    }
    return 'menuitem'
  }

  onClick(e) {
    const { disabled, header, divider, text } = this.props
    if (disabled || header || divider || text) {
      e.preventDefault()
      return
    }

    if (this.props.onClick) {
      this.props.onClick(e)
    }

    if (this.props.toggle) {
      this.context.toggle(e)
    }
  }

  getTabIndex() {
    const { disabled, header, divider, text } = this.props
    if (disabled || header || divider || text) {
      return '-1'
    }

    return '0'
  }

  render() {
    const tabIndex = this.getTabIndex()
    const role = tabIndex > -1 ? this.getRole() : undefined
    let { className, cssModule, divider, tag: Tag, header, active, text, ...props } = omit(this.props, ['toggle'])

    const classes = mapToCssModules(
      classNames(className, {
        disabled: props.disabled,
        'dropdown-item': !divider && !header && !text,
        active: active,
        'dropdown-header': header,
        'dropdown-divider': divider,
        'dropdown-item-text': text,
      }),
      cssModule
    )

    if (Tag === 'button') {
      if (header) {
        Tag = 'h6'
      } else if (divider) {
        Tag = 'div'
      } else if (props.href) {
        Tag = 'a'
      } else if (text) {
        Tag = 'span'
      }
    }

    return (
      <Tag
        type={Tag === 'button' && (props.onClick || this.props.toggle) ? 'button' : undefined}
        {...props}
        tabIndex={tabIndex}
        role={role}
        className={classes}
        onClick={this.onClick}
      />
    )
  }
}

DropdownItem.propTypes = propTypes
DropdownItem.defaultProps = defaultProps
DropdownItem.contextType = DropdownContext
