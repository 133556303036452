import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { canUseDOM } from '../utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  node: PropTypes.any,
}

class Portal extends React.Component {
  componentWillUnmount() {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode)
    }
    this.defaultNode = null
  }

  render() {
    if (!canUseDOM) {
      return null
    }

    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement('div')
      document.body.appendChild(this.defaultNode)
    }

    return ReactDOM.createPortal(this.props.children, this.props.node || this.defaultNode)
  }
}

Portal.propTypes = propTypes

export default Portal
