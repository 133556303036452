import { Model, Attr, SpraypaintBase, HasMany, BelongsTo, HasOne } from 'spraypaint'

let BASE_URL = ''

export type GroupCategorySmartMember = { id: string; name: string }
export type GroupCategoryStaticMember = { id: string; name: string }
export type ApplicationRecordWithName = ApplicationRecord & { name: string }

export type DialectPreferenceTypes = NetworkDialectPreference | LocationDialectPreference | PlayerDialectPreference
export interface DialectPreferenceCapable {
  newDialectPreference: (temp_id: string, dialect: { id: string; name: string }) => DialectPreferenceTypes
}

export interface OperatingHoursTimeBlockCapable {
  operatingHoursTimeblock: string
}

export interface OperatingHoursInheritedTimeBlockCapable {
  operatingHoursTimeblock: string
  operatingHoursTimeblockInherited: string
}

export interface DownloadTimeBlockCapable {
  downloadHoursTimeblock: string
  downloadMaxFileSize: number
}

export interface InheritedDownloadTimeBlockCapable {
  downloadHoursTimeblock: string
  downloadMaxFileSize: number
  downloadMaxFileSizeInherited: number
  downloadHoursTimeblockInherited: string
}

export interface OnDemandCapable {
  presentationOnDemandId: string
  presentationOnDemandType: string
  presentationOnDemand: { name: string }
}

export interface GroupCategoryCapable {
  groupCategoryId: string | null
}

export interface CustomizedTimeRangesCapable {
  customizedTimeRanges: CustomizedTimeRange[]
}

export interface BinAssignmentsCapable {
  binAssignments: BinAssignment[]
}

export interface ContentExclusionsCapable {
  contentExclusions: ContentExclusion[]
}

export interface LocationDialectsCapable {
  locationDialectPreferences: LocationDialectPreference[]
}

export interface TagsCapable {
  tags: Tag[]
}

export interface CustomFieldValueCapable {
  customFieldValues: CustomFieldValue[]
  modelClassName: string
  key: () => string | undefined
}

export type MediaSubTypes =
  | 'Audio'
  | 'AudioStream'
  | 'Bin'
  | 'Feed'
  | 'FlashMovie'
  | 'HTMLPage'
  | 'Image'
  | 'Media'
  | 'MediaRssFeed'
  | 'SmartGroup'
  | 'SpotSwap'
  | 'Video'

@Model()
export class ApplicationRecord extends SpraypaintBase {
  static baseUrl = BASE_URL
  static apiNamespace = '/api/v1'
  static clientApplication: string | null = 'NEOCAST'
  modelClassName = ''

  constructor(attrs?: Record<string, any>) {
    super(attrs)

    if (this.canUpdate === undefined) {
      this.canUpdate = false
    }

    if (this.canDelete === undefined) {
      this.canDelete = false
    }

    this.instantiatedAt = new Date()
  }

  static generateAuthHeader = (token: string) => token

  static jwt: string | undefined = ''

  // Tracks when this item was instantiated.
  //
  // Specifically do *NOT* mark this as an @Attr
  // since we do not want spraypaint to do any
  // tracking of changes, or marking the model
  // "dirty"
  instantiatedAt: Date

  @Attr({ persist: false }) canUpdate!: boolean
  @Attr({ persist: false }) canDelete!: boolean

  key(): string {
    return this.id ?? this.temp_id ?? '0'
  }

  dupDestructed<T extends ApplicationRecord>(): T {
    const newRecord = this.dup()
    // Ensure the temp-id is also copied
    newRecord.temp_id = this.temp_id

    newRecord.isMarkedForDestruction = true

    return newRecord as unknown as T
  }

  dupDisassociated<T extends ApplicationRecord>(): T {
    const newRecord = this.dup()
    // Ensure the temp-id is also copied
    newRecord.temp_id = this.temp_id

    newRecord.isMarkedForDisassociation = true

    return newRecord as unknown as T
  }

  // Returns a duplicate of this record with the changes applied
  dupWith<T extends ApplicationRecord>(changes = {}): T {
    // NOTE: `dup` from SpraypaintBase does not persist the necessary information
    //       to ensure dirty-tracking is retained in the new object. That is, if
    //       there was already a field changed on the current object, Spraypaint
    //       won't see that the field is changed on the new object.
    //
    // THUS: In this code we first get an object containing all the changes. We
    //       then restore all the fields to their initial value, make the duplicate
    //       and then restore all the fields in both the original as well as the
    //       duplicated object. This has the side effect of ensuring that both objects
    //       have all the fields marked as dirty.

    // Grab a copy of the changes
    const existingChanges = this.changes()

    // Reset all those fields
    Object.keys(existingChanges).forEach(key => {
      // @ts-ignore
      this[key] = existingChanges[key][0]
    })

    // Make the duplicate, but with the original attributes in place.
    const newRecord = this.dup()

    // Restore the changes
    Object.keys(existingChanges).forEach(key => {
      // @ts-ignore
      this[key] = newRecord[key] = existingChanges[key][1]
    })

    // Assign the new changes
    newRecord.attributes = Object.assign(newRecord.attributes, changes)

    // Ensure the temp-id is also copied
    newRecord.temp_id = this.temp_id

    // Return the newRecord as the correct type
    return newRecord as unknown as T
  }
}

@Model()
export class Dialect extends ApplicationRecord {
  static jsonapiType = 'dialects'
  modelClassName = 'Dialect'

  @Attr() code!: string
  @Attr() name!: string
  @HasMany('subtitles') subtitles!: Subtitle[]
}

@Model()
export class Player extends ApplicationRecord {
  static jsonapiType = 'players'
  modelClassName = 'Player'

  @Attr() type!: string
  @Attr({ persist: false }) screenshotTimestamp!: string
  @Attr({ persist: false }) screenshotUrl!: string
  @Attr({ persist: false }) tunnelPending!: boolean
  @Attr({ persist: false }) tunnelPort!: string

  @Attr({ persist: false }) playerGroupIds!: Array<string>
  @Attr({ persist: false }) playerGroupIdsWithOnDemand!: Array<string>

  @Attr() eth0ipassignment!: string
  @Attr() eth0staticipaddress!: string
  @Attr() eth0staticnetmask!: string
  @Attr() eth0staticgateway!: string
  @Attr() eth0staticdns1!: string
  @Attr() eth0staticdns2!: string
  @Attr() eth0staticdns3!: string

  @Attr() eth1ipassignment!: string
  @Attr() eth1staticipaddress!: string
  @Attr() eth1staticnetmask!: string
  @Attr() eth1staticgateway!: string
  @Attr() eth1staticdns1!: string
  @Attr() eth1staticdns2!: string
  @Attr() eth1staticdns3!: string
  @Attr() eth1wirelessessid!: string
  @Attr() eth1wirelesskey!: string

  @Attr({ persist: false }) allPresentations!: Presentation[]

  @Attr({ persist: false }) activeInterfaceHumanReadable!: string
  @Attr() activatedAt!: string
  @Attr() activeDnsServers!: string
  @Attr() deActivatedAt!: string
  @Attr() callInFrequencyMinutes!: string
  @Attr() callInUrl!: string
  @Attr() changePendingOnServer!: string
  @Attr() clientAddressableHostname!: string
  @Attr() clockDelta!: string
  @Attr() controlDuringOpenCloseHours!: boolean
  @Attr() createdAt!: Date
  @Attr() createdByName!: string
  @Attr({ persist: false }) diskUsageBytes!: number
  @Attr({ persist: false }) diskUsageDetails!: string
  @Attr({ persist: false }) diskUsagePercentage!: number
  @Attr({ persist: false }) displayCommunicationHasErrors!: boolean
  @Attr({ persist: false }) displayCommunicationHasReads!: boolean
  @Attr({ persist: false }) displayCommunicationHasWrites!: boolean
  @Attr() displayConfigurationObject!: string
  @Attr() displayConfigurationId!: string
  @Attr() displayControlFrequency!: number
  @Attr() displayModel!: string
  @Attr() displayOrientation!: string
  @Attr() displaySource!: string
  @Attr() displaySourceName!: string
  @Attr() displayStatus!: string
  @Attr() displayWeight!: string
  @Attr() displayVolume!: number
  @Attr() displayVolumeMaximum!: number
  @Attr() displayVolumeMinimum!: number
  @Attr() downloadHoursTimeblock!: string
  @Attr({ persist: false }) downloadHoursTimeblockEffective!: string
  @Attr({ persist: false }) downloadHoursTimeblockInherited!: string
  @Attr({ persist: false }) downloadHoursTimeblockInheritedFrom!: string
  @Attr({ persist: false }) downloadHoursSingleLine!: string
  @Attr() downloadMaxFileSize!: number
  @Attr({ persist: false }) downloadMaxFileSizeEffective!: number
  @Attr({ persist: false }) downloadMaxFileSizeInherited!: number
  @Attr({ persist: false }) downloadSize!: number
  @Attr({ persist: false }) downloadStatus!: string
  @Attr({ persist: false }) email!: string
  @Attr() eth1authentication!: string
  @Attr({ persist: false }) firmwareVersion!: string
  @Attr({ persist: false }) firstSeen!: Date
  @Attr() gateway!: string
  @Attr({ persist: false }) hasCopyOnBoot!: boolean
  @Attr({ persist: false }) hoursOpenPerWeek!: number
  @Attr() housekeepingFrequencyMinutes!: number
  @Attr() httpProxyUrl!: string
  @Attr({ persist: false }) id!: string
  @Attr({ persist: false }) inDownloadHours!: boolean
  @Attr() inEmergencyShutdownMode!: boolean
  @Attr({ persist: false }) inOperatingHours!: boolean
  @Attr() installedOn!: Date
  @Attr() ipAddress!: string
  @Attr({ persist: false }) isActive!: boolean
  @Attr({ persist: false }) lastDeliveredLogPlayertime!: Date
  @Attr({ persist: false }) lastDownloadedPresentationJsonServertime!: Date
  @Attr({ persist: false }) lastHeartbeatServertime!: Date
  @Attr({ persist: false }) lastHousekeepingServertime!: Date
  @Attr({ persist: false }) lastPowerOn!: Date

  @Attr({ persist: false }) lastRetrieveSpeedtestServertime!: Date

  @Attr({ persist: false }) lastRetrievedLogsServertime!: Date
  @Attr({ persist: false }) nextRetrieveLogsServertime!: Date

  @Attr({ persist: false }) lastRetrievedStatusServertime!: Date
  @Attr({ persist: false }) nextRetrieveStatusServertime!: Date

  @Attr({ persist: false }) lastSeen!: Date
  @Attr({ persist: false }) nextSeen!: Date

  @Attr({ persist: false }) lastSeenIp!: string

  @Attr({ persist: false }) lastSynchronizedConfigurationServertime!: Date
  @Attr({ persist: false }) nextSynchronizeConfigurationServertime!: Date

  @Attr({ persist: false }) lastSynchronizedContentServertime!: Date
  @Attr({ persist: false }) nextSynchronizeContentServertime!: Date

  @Attr({ persist: false }) lastSynchronizedOnDemandContentServertime!: Date
  @Attr({ persist: false }) lastSynchronizedPluginsServertime!: Date

  @Attr({ persist: false }) linkLevel!: number
  @Attr({ persist: false }) linkNoise!: number
  @Attr({ persist: false }) linkQuality!: number

  @Attr({ persist: false }) locationName!: string
  @Attr() locationId!: string
  @Attr({ persist: false }) networkName!: string
  @Attr() macAddress!: string
  @Attr({ persist: false }) modelNumber!: string
  @Attr({ persist: false }) mediaPartitionBytesTotal!: number
  @Attr() name!: string
  @Attr() networkId!: string
  @Attr({ persist: false }) needsPresentationJsonDownload!: boolean
  @Attr() neocastDataNeedsUpdate!: string
  @Attr() ntpServers!: string
  @Attr() onDemandNeedsRefresh!: string
  @Attr() onDemandRemoteName!: string
  @Attr({ persist: false }) online!: boolean
  @Attr() // operatingHoursFrom!: string
  @Attr()
  operatingHoursTimeblock!: string
  @Attr({ persist: false }) operatingHoursTimeblockEffective!: string
  @Attr({ persist: false }) operatingHoursTimeblockInherited!: string
  @Attr({ persist: false }) operatingHoursTimeblockInheritedFrom!: string
  @Attr({ persist: false }) operatingHoursSingleLine!: string
  @Attr({ persist: false }) operatingSystem!: string
  @Attr({ persist: false }) pendingDownloadsTotalByteCount!: number
  @Attr({ persist: false }) pendingDownloadsTotalFileCount!: number
  @Attr() pendingFirmwareUpdateFilename!: string
  @Attr() pendingFirmwareUpdateStarttime!: string
  @Attr({ persist: false }) phone!: string
  @Attr({ persist: false }) playerTime!: Date
  @Attr({ persist: false }) playlistNeedsRefresh!: boolean
  @Attr() presentationOnDemandId!: string
  @Attr() presentationOnDemandType!: string
  @Attr({ persist: false }) pusherAccessCode!: string
  @Attr() rebootPending!: string
  @Attr() retrieveLogsFrequencyMinutes!: string
  @Attr() retrieveMuninDataFrequencyMinutes!: string
  @Attr() retrieveSpeedtestFrequencyMinutes!: string
  @Attr() retrieveStatusFrequencyMinutes!: string
  @Attr() screenShotPending!: string
  @Attr({ persist: false }) speedTestDownloadMbps!: number
  @Attr({ persist: false }) speedTestPing!: number
  @Attr({ persist: false }) speedTestUploadMbps!: number
  @Attr() speedTestUrl!: string
  @Attr() synchronizeConfigurationFrequencyMinutes!: string
  @Attr() synchronizeContentFrequencyMinutes!: string
  @Attr() synchronizeOnDemandContentFrequencyMinutes!: string
  @Attr() synchronizePluginsFrequencyMinutes!: string
  @Attr() tagList!: string
  @Attr() timezone!: string
  @Attr() triggerListenerEnabled!: boolean
  @Attr() triggerListenerPort!: string
  @Attr({ persist: false }) pendingTunnelRequestCount!: number
  @Attr({ persist: false }) uncompletedDeviceFileDeletionCount!: number
  @Attr({ persist: false }) updatedAge!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) updatedByName!: string
  @Attr({ persist: false }) uptime!: number
  @Attr() videoOutputMode!: string
  @Attr() watchdogIntervalSeconds!: string
  @Attr() tagIds!: string[]

  @Attr({ persist: false }) playerGroups!: {
    id: number
    name: string
    via: string
    via_type: 'player' | 'location' | 'network' | 'smart_group'
  }[]
  @Attr({ persist: false }) smartGroups!: {
    id: number
    name: string
    via: string
    via_type: 'player' | 'location' | 'network' | 'smart_group'
  }[]

  @HasMany() tags!: Tag[]
  @HasMany() assignedFiles!: AssignedFile[]
  @HasMany() binAssignments!: BinAssignment[]
  @HasMany() contentExclusions!: ContentExclusion[]
  @HasMany() customFieldValues!: CustomFieldValue[]
  @BelongsTo() customer!: Customer
  @HasMany() directoryFiles!: DirectoryFile[]
  @BelongsTo() displayConfiguration!: DisplayConfiguration
  @BelongsTo() location!: Location
  @BelongsTo() network!: Network
  @HasMany() playerDialectPreferences!: PlayerDialectPreference[]
  @HasMany() videoOutputs!: VideoOutput[]
  @HasMany() customizedTimeRanges!: CustomizedTimeRange[]
  @HasMany() logs!: Log[]
  @BelongsTo() presentationOnDemand!: Media | MediaGroup | SmartGroup

  newDialectPreference(temp_id: string, dialect: { id: string; name: string }) {
    const dialectPreference = new PlayerDialectPreference()
    dialectPreference.modelClassName = 'PlayerDialectPreference'
    dialectPreference.dialect = new Dialect({ id: dialect.id, name: dialect.name })
    dialectPreference.dialectId = dialect.id
    dialectPreference.playerId = this.id || '0'
    dialectPreference.temp_id = temp_id

    return dialectPreference
  }
}

@Model()
export class Plugin extends ApplicationRecord {
  static jsonapiType = 'plugins'
  modelClassName = 'Plugin'

  @Attr() name!: string
  @Attr() fieldsText!: string
  @Attr() fields!: string[]
}

@Model()
export class VideoOutput extends ApplicationRecord {
  static jsonapiType = 'video_outputs'
  modelClassName = 'VideoOutput'

  @BelongsTo() player!: Player
  @HasMany() videoOutputResolutions!: VideoOutputResolution[]

  @Attr() name!: string
  @Attr() width!: number
  @Attr() height!: number
  @Attr() xpos!: number
  @Attr() ypos!: number
  @Attr() displayOrder!: number
  @Attr() enabled!: boolean
  @Attr() connected!: boolean
  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
}

@Model()
export class VideoOutputResolution extends ApplicationRecord {
  static jsonapiType = 'video_output_resolutions'
  modelClassName = 'VideoOutputResolution'

  @BelongsTo() videoOutput!: VideoOutput

  @Attr() width!: number
  @Attr() height!: number
  @Attr() refresh!: number
  @Attr() active!: boolean
  @Attr() createdAt!: Date
  @Attr() updatedAt!: Date
}

@Model()
export class DisplayConfiguration extends ApplicationRecord {
  static jsonapiType = 'display_configurations'
  modelClassName = 'DisplayConfiguration'

  @Attr() name!: string
  @Attr() screenResolutionX!: number
  @Attr() screenResolutionY!: number
  @Attr() screenArrayX!: number
  @Attr() screenArrayY!: number
  @Attr() orientation!: string
}

export class DisplayConfigurationAttributes {
  id!: number
  name!: string
  screenResolutionX!: number
  screenResolutionY!: number
  screenArrayX!: number
  screenArrayY!: number
  orientation!: string
}

@Model()
export class Location extends ApplicationRecord {
  static jsonapiType = 'locations'
  modelClassName = 'Location'

  @BelongsTo() network!: Network
  @BelongsTo() customer!: Customer
  @HasMany() binAssignments!: BinAssignment[]
  @HasMany() locationDialectPreferences!: LocationDialectPreference[]
  @HasMany() contentExclusions!: ContentExclusion[]
  @HasMany() customizedTimeRanges!: CustomizedTimeRange[]
  @HasMany() tags!: Tag[]
  @HasMany() customFieldValues!: CustomFieldValue[]
  @BelongsTo() presentationOnDemand!: Media | MediaGroup | SmartGroup
  @HasOne() address!: Address

  @Attr({ persist: false }) allPresentations!: Presentation[]

  @Attr({ persist: false }) playerGroups!: {
    id: number
    name: string
    via: string
    via_type: 'player' | 'location' | 'network' | 'smart_group'
  }[]

  @Attr() name!: string
  @Attr() onDemandSecurityCode!: string
  @Attr() onDemandRemoteLoginCount!: number
  @Attr() maxDownloadSpeedKilobytes!: number
  @Attr() presentationOnDemandType!: MediaSubTypes
  @Attr() presentationOnDemandId!: string
  @Attr({ persist: false }) networkName!: string

  @Attr({ persist: false }) playerCount!: number

  @Attr() networkId?: string

  @Attr() streetLine1!: string
  @Attr() streetLine2!: string
  @Attr() stateOrProvince!: string
  @Attr() postalCode!: string
  @Attr() country!: string
  @Attr() city!: string
  @Attr() lat!: number
  @Attr() lng!: number

  @Attr() downloadHoursTimeblock!: string
  @Attr({ persist: false }) downloadHoursTimeblockEffective!: string
  @Attr({ persist: false }) downloadHoursTimeblockInherited!: string
  @Attr({ persist: false }) downloadHoursTimeblockInheritedFrom!: string
  @Attr({ persist: false }) downloadMaxFileSizeInherited!: number
  @Attr({ persist: false }) downloadMaxFileSizeEffective!: number
  @Attr() downloadMaxFileSize!: number

  @Attr() operatingHoursTimeblock!: string
  @Attr({ persist: false }) operatingHoursTimeblockEffective!: string
  @Attr({ persist: false }) operatingHoursTimeblockInherited!: string
  @Attr({ persist: false }) operatingHoursTimeblockInheritedFrom!: string

  @Attr() district!: string
  @Attr() region!: string
  @Attr() phone!: string
  @Attr() email!: string
  @Attr() itContact!: string
  @Attr() storeNumber!: string

  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string

  @Attr() tagIds!: string[]

  newDialectPreference(temp_id: string, dialect: { id: string; name: string }) {
    const dialectPreference = new LocationDialectPreference()
    dialectPreference.modelClassName = 'LocationDialectPreference'
    dialectPreference.dialect = new Dialect({ id: dialect.id, name: dialect.name })
    dialectPreference.dialectId = dialect.id
    dialectPreference.locationId = this.id || '0'
    dialectPreference.temp_id = temp_id

    return dialectPreference
  }
}

@Model()
export class Network extends ApplicationRecord {
  static jsonapiType = 'networks'
  modelClassName = 'Network'

  @HasMany() locations!: Location[]
  @HasMany() players!: Player[]
  @BelongsTo() customer!: Customer
  @HasMany() binAssignments!: BinAssignment[]
  @HasMany() networkDialectPreferences!: NetworkDialectPreference[]
  @HasMany() contentExclusions!: ContentExclusion[]
  @HasMany() customizedTimeRanges!: CustomizedTimeRange[]
  @HasMany() tags!: Tag[]
  @HasMany() customFieldValues!: CustomFieldValue[]
  @BelongsTo() presentationOnDemand!: Media | MediaGroup | SmartGroup
  @BelongsTo('media') onDemandTitleLogoMedia!: Media

  @Attr({ persist: false }) allPresentations!: Presentation[]
  @Attr({ persist: false }) playerGroups!: {
    id: number
    name: string
    via: string
    via_type: 'player' | 'location' | 'network' | 'smart_group'
  }[]

  newDialectPreference(temp_id: string, dialect: { id: string; name: string }) {
    const dialectPreference = new NetworkDialectPreference()
    dialectPreference.modelClassName = 'NetworkDialectPreference'
    dialectPreference.dialect = new Dialect({ id: dialect.id, name: dialect.name })
    dialectPreference.dialectId = dialect.id
    dialectPreference.networkId = this.id || '0'
    dialectPreference.temp_id = temp_id // dummyIdGenerator()

    return dialectPreference
  }

  @Attr() description!: string
  @Attr() downloadHoursTimeblock!: string
  @Attr() downloadMaxFileSize!: number
  @Attr() name!: string
  @Attr({ persist: false }) onDemandCustomized!: boolean
  @Attr({ persist: false }) locationCount!: number
  @Attr({ persist: false }) playerCount!: number
  @Attr() onDemandTitleBackgroundColor!: string
  @Attr() onDemandTitleLogoMediaId!: string
  @Attr() onDemandTitleLogoMediaType!: MediaSubTypes
  @Attr() onDemandTitleLogoMediaName!: string
  @Attr() onDemandTitleText!: string
  @Attr() onDemandTitleTextColor!: string
  @Attr() operatingHoursTimeblock!: string
  @Attr() presentationOnDemandId!: string
  @Attr() presentationOnDemandType!: MediaSubTypes
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) updatedByName!: string
  @Attr() tagIds!: string[]
}

@Model()
export class MediaGroup extends ApplicationRecord {
  static jsonapiType = 'media_groups'
  modelClassName = 'MediaGroup'

  @BelongsTo() groupCategory!: GroupCategory
  @Attr() groupCategoryId!: string

  @HasMany() mediaGroupItems!: MediaGroupItem[]
  @HasMany() duplicateMediaGroups!: DuplicateMediaGroup[]

  @Attr({ persist: false }) allPresentations!: Presentation[]

  @Attr() name!: string
  @Attr() playbackStyle!: string
  @Attr() isLocked!: boolean
  @Attr({ persist: false }) totalFileSize!: number
  @Attr({ persist: false }) mediaGroupItemsCount!: number
  @Attr({ persist: false }) folderName!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string

  // Returns a new media group id, or false if unable to duplicate
  async duplicate() {
    const newMediaGroup = new DuplicateMediaGroup()
    newMediaGroup.mediaGroupId = this.id

    const success = await newMediaGroup.save()
    if (success) {
      return newMediaGroup.mediaGroupId
    } else {
      return false
    }
  }
}

@Model()
export class MediaGroupItem extends ApplicationRecord {
  static jsonapiType = 'media_group_items'
  modelClassName = 'MediaGroupItem'

  @BelongsTo() media!: Media
  @BelongsTo() mediaGroup!: MediaGroup
  @Attr() mediaId?: string
  @Attr() position!: number
}

@Model()
export class Tag extends ApplicationRecord {
  static jsonapiType = 'tags'
  modelClassName = 'Tag'

  @BelongsTo() tagGroup!: TagGroup
  @BelongsTo() taggings!: Tagging

  @Attr() name!: string
  @Attr() tagGroupId!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string
}

@Model()
export class TagGroup extends ApplicationRecord {
  static jsonapiType = 'tag_groups'
  modelClassName = 'TagGroup'

  @HasMany() tags!: Tag[]

  @Attr() name!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string
}

@Model()
export class Tagging extends ApplicationRecord {
  static jsonapiType = 'taggings'
  modelClassName = 'Tagging'

  @BelongsTo() tag!: Tag

  @Attr() tagId!: string
  @Attr() taggableId!: string
  @Attr() taggableType!: string
}

@Model()
export class Font extends ApplicationRecord {
  static jsonapiType = 'fonts'
  modelClassName = 'Font'

  @Attr() name!: string
  @Attr() fileName!: string
  @Attr() webuiDisplayName!: string
}

@Model()
export class Firmware extends ApplicationRecord {
  static jsonapiType = 'firmwares'
  modelClassName = 'Firmware'

  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) fileName!: string
  @Attr({ persist: false }) availableToAllCustomers!: boolean
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdAt!: Date
}

@Model()
export class Address extends ApplicationRecord {
  static jsonapiType = 'addresses'
  modelClassName = 'Address'

  @Attr() streetLine1!: string
  @Attr() streetLine2!: string
  @Attr() stateOrProvince!: string
  @Attr() postalCode!: string
  @Attr() country!: string
  @Attr() city!: string
  @Attr() lat!: number
  @Attr() lng!: number
}

@Model()
export class ApiKey extends ApplicationRecord {
  static jsonapiType = 'api_keys'
  modelClassName = 'ApiKey'

  @Attr() accessToken!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
}

@Model()
export class AssignedFile extends ApplicationRecord {
  static jsonapiType = 'assigned_files'
  modelClassName = 'AssignedFile'

  @Attr() directoryId!: string
  @Attr() deviceId!: string
  @Attr() onDeviceName!: string
  @Attr() length!: number
  @Attr() checksumType!: string
  @Attr() checksumValue!: string
  @Attr() deliveryMechanismId!: string
  @Attr() awaitingDownload!: boolean
  @Attr() deviceType!: string
  @Attr() downloadableType!: string
  @Attr() downloadableId!: string
  @Attr() earliestNeedByDate!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdAt!: Date
  @Attr() category!: string
  @Attr() explicitUrl!: string
  @Attr() failedDownloadAttempts!: number
}

@Model()
export class CustomField extends ApplicationRecord {
  static jsonapiType = 'custom_fields'
  modelClassName = 'CustomField'

  @Attr() name!: string
  @Attr() model!: string
  @Attr() dataType!: string
  @HasMany() customFieldValues!: CustomFieldValue[]
}

@Model()
export class CustomFieldValue extends ApplicationRecord {
  static jsonapiType = 'custom_field_values'
  modelClassName = 'CustomFieldValue'

  @BelongsTo() customField!: CustomField
  @BelongsTo() model!: CustomFieldValueCapable // Presentation | Media | Player | Location | Network
  @Attr() customFieldId?: string

  @Attr() modelId?: string
  @Attr() modelType!: string
  @Attr() value!: string
  @Attr() enabled!: boolean
}

@Model()
export class ResetPassword extends ApplicationRecord {
  static jsonapiType = 'reset_passwords'
  modelClassName = 'ResetPassword'

  @Attr() token!: string
  @Attr() password!: string
  @Attr() passwordConfirmation!: string
}

@Model()
export class ResetPasswordRequest extends ApplicationRecord {
  static jsonapiType = 'reset_password_requests'
  modelClassName = 'ResetPasswordRequest'

  @Attr() email!: string
}

@Model()
export class PlayerBulkAction extends ApplicationRecord {
  static jsonapiType = 'player_bulk_actions'
  modelClassName = 'PlayerBulkAction'

  @Attr() ids!: string[]
  @Attr() request!: object
  @Attr() response!: { ok: boolean; updated_count: number }
}

@Model()
export class LocationBulkAction extends ApplicationRecord {
  static jsonapiType = 'location_bulk_actions'
  modelClassName = 'LocationBulkAction'

  @Attr() ids!: string[]
  @Attr() request!: object
  @Attr() response!: { ok: boolean; updated_count: number }
}

@Model()
export class NetworkBulkAction extends ApplicationRecord {
  static jsonapiType = 'network_bulk_actions'
  modelClassName = 'NetworkBulkAction'

  @Attr() ids!: string[]
  @Attr() request!: object
  @Attr() response!: { ok: boolean; updated_count: number }
}

@Model()
export class MediaBulkAction extends ApplicationRecord {
  static jsonapiType = 'media_bulk_actions'
  modelClassName = 'MediaBulkAction'

  @Attr() ids!: string[]
  @Attr() request!: object
  @Attr() response!: { ok: boolean; updated_count: number }
}

@Model()
export class PlayerDialectPreference extends ApplicationRecord {
  static jsonapiType = 'player_dialect_preferences'
  modelClassName = 'PlayerDialectPreference'

  @Attr() playerId!: string
  @Attr() dialectId?: string
  @Attr() position!: number
  @Attr() mediaViewStyle!: 'list' | 'thumbnails'
  @BelongsTo() player!: Player
  @BelongsTo() dialect!: Dialect
}

@Model()
export class NetworkDialectPreference extends ApplicationRecord {
  static jsonapiType = 'network_dialect_preferences'
  modelClassName = 'NetworkDialectPreference'

  @Attr() networkId?: string
  @Attr() dialectId?: string
  @Attr() position!: number
  @BelongsTo() network!: Network
  @BelongsTo() dialect!: Dialect
}

@Model()
export class LocationDialectPreference extends ApplicationRecord {
  static jsonapiType = 'location_dialect_preferences'
  modelClassName = 'LocationDialectPreference'

  @Attr() locationId!: string
  @Attr() dialectId!: string
  @Attr() position!: number
  @BelongsTo() location!: Location
  @BelongsTo() dialect!: Dialect
}

@Model()
export class BinAssignment extends ApplicationRecord {
  static jsonapiType = 'bin_assignments'
  modelClassName = 'BinAssignment'

  @Attr() binId!: string
  @Attr() distributableId!: string
  @Attr() distributableType!: string
  @Attr() mediaId!: string

  @Attr() mediaName!: string
  @Attr() mediaClass!: MediaSubTypes
  @Attr() binName!: string

  @BelongsTo() media!: Media
  @BelongsTo() distributable!: Network | Location | Player | PlayerGroup | SmartGroup

  _mediaName?: string
  _mediaClass?: string
}

@Model()
export class ReportRequest extends ApplicationRecord {
  static jsonapiType = 'report_requests'
  modelClassName = 'ReportRequest'

  @Attr({ persist: false }) startedAt!: Date
  @Attr({ persist: false }) completedAt!: Date
  @Attr({ persist: false }) requestedAt!: Date
  @Attr() parameters!: any
  @Attr({ persist: false }) message!: string
  @Attr() targetType!: string
  @Attr() targetId!: string
  @Attr() reportClassName!: string
  @Attr() resubmit!: boolean

  @Attr({ persist: false }) requestingUserId!: string
  @Attr({ persist: false }) customerId!: string

  @Attr({ persist: false }) fileName!: string
  @Attr({ persist: false }) url!: string

  @Attr({ persist: false }) completed!: boolean
  @Attr({ persist: false }) active!: boolean
  @Attr({ persist: false }) failed!: boolean
  @Attr({ persist: false }) pending!: boolean
  @Attr({ persist: false }) resubmittable!: boolean

  @BelongsTo() target!: Network | Location | Player | Media | SpotSwapItem

  @BelongsTo() customer!: Customer
  @BelongsTo('user') requestingUser!: User
}

@Model()
export class GroupCategory extends ApplicationRecord {
  static jsonapiType = 'group_categories'
  modelClassName = 'GroupCategory'

  @Attr() name!: string
  @Attr() targetClass!: string
  @Attr({ persist: false }) members!: { smart: GroupCategorySmartMember[]; static: GroupCategoryStaticMember[] }
  @Attr({ persist: false }) mediaGroupsCount!: number
  @Attr({ persist: false }) playerGroupsCount!: number
  @Attr({ persist: false }) smartGroupsCount!: number
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
}

@Model()
export class Customer extends ApplicationRecord {
  static jsonapiType = 'customers'
  modelClassName = 'Customer'

  @Attr() name!: string
  @Attr() usesCustomBannerColor!: boolean
  @Attr() bannerColor!: string
  @Attr() directorPrimaryColorStart!: string
  @Attr() directorPrimaryColorEnd!: string
  @Attr() directorBrandName!: string
  @Attr() directorBrandImage!: string
  @Attr() earliestPossibleLogDate!: Date
  @Attr() onDemandRemoteBannerColor!: string
  @Attr() onDemandRemoteControlsBgColor!: string
  @Attr() onDemandRemoteControlsTextColor!: string
  @Attr() onDemandRemoteBannerTextColor!: string
  @Attr() onDemandRemoteBannerText!: string
  @Attr() onDemandCustomLogo!: string
  @Attr() newLogoUploadId!: string
  @Attr() emailContact!: string
  @Attr() usesCompositing!: boolean

  @Attr() defaultImageRuntimeMilliseconds!: number
  @Attr() defaultSpotSwapRuntimeMilliseconds!: number
  @Attr() defaultHtmlPageRuntimeMilliseconds!: number
  @Attr() defaultFlashMovieRuntimeMilliseconds!: number

  @Attr() authenticationFailureCount!: number
  @Attr() minimumPasswordStrength!: number
  @Attr() inEmergencyShutdownMode!: boolean

  @Attr() emergencyShutdownUserOne!: string
  @Attr() emergencyShutdownUserTwo!: string
  @Attr() emergencyShutdownPasswordOne!: string
  @Attr() emergencyShutdownPasswordTwo!: string

  @Attr() metadataUploadId!: string

  @HasMany() customFields!: CustomField[]
  @HasMany() apiKeys!: ApiKey[]
  @HasMany() tags!: Tag[]
  @HasMany() tagGroups!: TagGroup[]
  @HasMany() timeRanges!: TimeRange[]
}

export type SmartGroupTypes = 'PlayerSmartGroup' | 'MediaSmartGroup' | 'PresentationSmartGroup'
export type SmartGroupCapableModels = 'Player' | 'Media' | 'Presentation'
export type SmartGroupCapableModelsLowerCase = 'player' | 'media' | 'presentation'

@Model()
export class SmartGroup extends ApplicationRecord {
  static jsonapiType = 'smart_groups'
  modelClassName = 'SmartGroup'

  @HasMany() smartGroupConditions!: SmartGroupCondition[]
  @BelongsTo() groupCategory!: GroupCategory
  @Attr() groupCategoryId!: string

  @Attr({ persist: false }) allPresentations!: Presentation[]

  @Attr() name!: string
  @Attr() type!: SmartGroupTypes
  @Attr() joinType!: string
  @Attr() useInPresentationContentLibrary!: boolean
  @Attr() playbackStyle!: string
  @Attr() targetClass!: string
  @Attr() members!: object
  @Attr({ persist: false }) totalFileSize!: number
  @Attr({ persist: false }) smartGroupMembersCount!: number
  @Attr({ persist: false }) smartGroupConditionsCount!: number
  @Attr({ persist: false }) folderName!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string

  // Returns a new smart group id, or false if unable to duplicate
  async duplicate() {
    const newSmartGroup = new DuplicateSmartGroup()
    newSmartGroup.smartGroupId = this.id

    const success = await newSmartGroup.save()
    if (success) {
      return newSmartGroup.smartGroupId
    } else {
      return false
    }
  }
}

@Model()
export class PlayerSmartGroup extends SmartGroup {
  static jsonapiType = 'player_smart_groups'
  modelClassName = 'PlayerSmartGroup'
}

@Model()
export class MediaSmartGroup extends SmartGroup {
  static jsonapiType = 'media_smart_groups'
  modelClassName = 'MediaSmartGroup'
}

@Model()
export class SmartGroupCondition extends ApplicationRecord {
  static jsonapiType = 'smart_group_conditions'
  modelClassName = 'SmartGroupCondition'

  @BelongsTo() smartGroup!: SmartGroup
  @Attr() smartGroupId!: string

  @Attr() comparitor!: string
  @Attr() value!: string
  @Attr() column!: string
}

@Model()
export class SpotSwapItem extends ApplicationRecord {
  static jsonapiType = 'spot_swap_items'
  modelClassName = 'SpotSwapItem'

  @BelongsTo() media!: Media

  @Attr() name!: string
  @Attr() fileName!: string
  @Attr() cachedFileSize!: number
  @Attr() startDate!: string
  @Attr() endDate!: string
  @Attr() originalFileName!: string
  @Attr() description!: string
  @Attr() cachedMd5sum!: string
  @Attr() downloadUrl!: string
  @Attr() distributableType!: string
  @Attr() distributableId!: string
  @Attr() distributableDescription!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string

  // Indicates that this spot swap is newly created
  newlyCreated?: boolean
}

@Model()
export class TimeRange extends ApplicationRecord {
  static jsonapiType = 'time_ranges'
  modelClassName = 'TimeRange'

  @Attr() name!: string
  @Attr() timeblock!: string
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdAt!: Date

  @BelongsTo() customer!: Customer
}

@Model()
export class FeedItem extends ApplicationRecord {
  static jsonapiType = 'feed_items'
  modelClassName = 'FeedItem'

  @BelongsTo() media!: Media

  @Attr() title!: string
  @Attr() description!: string
  @Attr() position!: number
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string
}

@Model()
export class DisplayModelVendor extends ApplicationRecord {
  static jsonapiType = 'display_model_vendors'
  modelClassName = 'DisplayModelVendor'

  @Attr() name!: string
  @HasMany() displayDrivers!: DisplayDriver[]
}

@Model()
export class DisplayModelSource extends ApplicationRecord {
  static jsonapiType = 'display_model_sources'
  modelClassName = 'DisplayModelSource'

  @Attr() name!: string
  @Attr() modelSpecificValue!: string
  @BelongsTo() displayDriver!: DisplayDriver
  @BelongsTo() displayModel!: DisplayModel
}

@Model()
export class DisplayModel extends ApplicationRecord {
  static jsonapiType = 'display_models'
  modelClassName = 'DisplayModel'

  @Attr() name!: string
  @BelongsTo() displayDriver!: DisplayDriver
  @BelongsTo() displayModelVendor!: DisplayModelVendor
  @BelongsTo() displayModelSources!: DisplayModelVendor[]
}

@Model()
export class DisplayDriver extends ApplicationRecord {
  static jsonapiType = 'display_drivers'
  modelClassName = 'DisplayDriver'

  @Attr() name!: string
  @Attr() volumeLow!: number
  @Attr() volumeHigh!: number
  @BelongsTo() displayModelVendor!: DisplayModelVendor
  @HasMany() displayModelSources!: DisplayModelSource[]
  @HasMany() displayModels!: DisplayModel[]
}

@Model()
export class DirectoryFile extends ApplicationRecord {
  static jsonapiType = 'directory_files'
  modelClassName = 'DirectoryFile'

  @Attr() directoryId!: string
  @Attr() deviceId!: string
  @Attr() name!: string
  @Attr() length!: number
  @Attr() md5Sum!: string
  @Attr() lastAssignedDate!: Date
  @Attr() raw!: string
}

@Model()
export class PresentationEditTracking extends ApplicationRecord {
  static jsonapiType = 'presentation_edit_trackings'
  modelClassName = 'PresentationEditTracking'

  @Attr() details!: string
  @Attr() presentationId!: string
}

@Model()
export class Presentation extends ApplicationRecord {
  static jsonapiType = 'presentations'
  modelClassName = 'Presentation'

  @Attr() name!: string
  @Attr() presentationJson!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr() isLaunched!: boolean
  @Attr() startDate!: string | null
  @Attr() endDate!: string | null
  @Attr() showInDirector!: boolean
  @Attr() playerCount!: number
  @Attr({ persist: false }) updatedByName!: string
  @Attr() duration!: number
  @Attr() timeblock!: string | null
  @Attr() timeRangeId!: string | null
  @Attr() dataConditionJson!: string
  @Attr() displayConfigurationId!: string
  @Attr() tagIds!: string[]
  @Attr() pluginIds!: string[]

  @Attr() networkIdsAndNames!: [string, string][]
  @Attr() locationIdsAndNames!: [string, string][]
  @Attr() playerIdsAndNames!: [string, string][]
  @Attr() smartGroupIdsAndNames!: [string, string][]
  @Attr() playerGroupIdsAndNames!: [string, string][]
  @Attr({ persist: false }) playerDistributionDetails!: [number, string, number][]

  @HasMany() plugins!: Plugin[]
  @HasMany() tags!: Tag[]
  @BelongsTo() displayConfiguration!: DisplayConfiguration
  @BelongsTo() playerDistribution!: PlayerDistribution
  @BelongsTo() timeRange!: TimeRange
}

@Model()
export class PlayerGroup extends ApplicationRecord {
  static jsonapiType = 'player_groups'
  modelClassName = 'PlayerGroup'

  @BelongsTo() groupCategory!: GroupCategory
  @Attr() groupCategoryId!: string
  @BelongsTo() playerDistribution!: PlayerDistribution
  @HasMany() contentExclusions!: ContentExclusion[]
  @BelongsTo() presentationOnDemand!: Media | MediaGroup | SmartGroup

  @Attr() name!: string
  @Attr({ persist: false }) members!: object
  @Attr() presentationOnDemandId!: string
  @Attr() presentationOnDemandType!: MediaSubTypes
  @Attr({ persist: false }) deviceCount!: string
  @Attr({ persist: false }) folderName!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string

  @Attr({ persist: false }) allPresentations!: Presentation[]

  // Returns a new media group id, or false if unable to duplicate
  async duplicate() {
    const newPlayerGroup = new DuplicatePlayerGroup()
    newPlayerGroup.playerGroupId = this.id

    const success = await newPlayerGroup.save()
    if (success) {
      return newPlayerGroup.playerGroupId
    } else {
      return false
    }
  }
}

@Model()
export class ContentExclusion extends ApplicationRecord {
  static jsonapiType = 'content_exclusions'
  modelClassName = 'ContentExclusion'

  @Attr() mediaId!: string
  @Attr() customerId!: string
  @Attr() excluderType!: string
  @Attr() excluderId!: string

  @BelongsTo() media!: Media
  @BelongsTo() customer!: Customer
  @BelongsTo() excluder!: Network | Location | Player | PlayerGroup

  // TODO: Better type
  _media?: object
}

@Model()
export class CustomizedTimeRange extends ApplicationRecord {
  static jsonapiType = 'customized_time_ranges'
  modelClassName = 'CustomizedTimeRange'

  @Attr() timeblock!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr() timeRangeId?: string
  @BelongsTo() timeRange!: TimeRange
}

export type GranularPermissionTypes = 'Network' | 'Player' | 'Media' | 'Location'

@Model()
export class GranularPermission extends ApplicationRecord {
  static jsonapiType = 'granular_permissions'
  modelClassName = 'GranularPermission'

  @BelongsTo() user!: User
  @BelongsTo() permissable!: Player | Media | MediaGroup

  @Attr() userId!: string
  @Attr() permission!: string // TODO: Should this be a stronger type?
  @Attr() permissableId!: string
  @Attr() permissableType!: GranularPermissionTypes
  @Attr({ persist: false }) permissableName!: string
}

@Model()
export class PlayerDistribution extends ApplicationRecord {
  static jsonapiType = 'player_distributions'
  modelClassName = 'PlayerDistribution'

  @HasMany() networks!: Network[]
  @HasMany() locations!: Location[]
  @HasMany() players!: Player[]
  @HasMany() playerGroups!: PlayerGroup[]
  @HasMany() smartGroups!: SmartGroup[]

  @Attr() playerCount!: number
}

@Model()
export class User extends ApplicationRecord {
  static jsonapiType = 'users'
  modelClassName = 'User'

  @Attr() login!: string
  @Attr() email!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr() name!: string
  @Attr({ persist: false }) customerId!: string
  @Attr() lastLoginAt!: Date
  @Attr() locale!: string
  @Attr() resetPasswordSentAt!: Date
  @Attr() signInCount!: number
  @Attr() currentSignInAt!: Date
  @Attr() lastSignInAt!: Date
  @Attr() currentSignInIp!: string
  @Attr() lastSignInIp!: string
  @Attr() failedAttempts!: number
  @Attr() unlockToken!: string
  @Attr() lockedAt!: Date
  @Attr() mustChangePassword!: boolean
  @Attr() locked!: boolean
  @Attr() resetPreferences!: boolean
  @Attr() tagIds!: string[]

  @Attr() password!: string
  @Attr() passwordConfirmation!: string
  @Attr() adminPasswordForPasswordModification!: string

  @Attr() roleNames!: string[]
  @Attr() roleOrGranular!: 'role' | 'granular'

  @Attr() isAdmin!: boolean
  @Attr() isSuperUser!: boolean

  @Attr({ persist: false }) customerIsExpired!: boolean

  @Attr() gupViewPlayers!: boolean
  @Attr() gupUpdatePlayers!: boolean
  @Attr() gupDeletePlayers!: boolean
  @Attr() gupViewMedia!: boolean
  @Attr() gupUpdateMedia!: boolean
  @Attr() gupDeleteMedia!: boolean

  @BelongsTo() customer!: Customer
  @HasMany() granularPermissions!: GranularPermission[]
  @HasMany() customFieldValues!: CustomFieldValue[]
}

@Model()
export class Preference extends ApplicationRecord {
  static jsonapiType = 'preferences'
  modelClassName = 'Preference'

  // Make these more specific objects for type safety
  @Attr() pageColumns!: Record<string, any>
  @Attr() sortOrders!: Record<string, any>
  @Attr() pagination!: object
  @Attr() mediaViewStyle!: string

  sortKey(model: string): string {
    const [sortKey] = (this.sortOrders[model] || 'name ASC').split(' ')

    return sortKey
  }

  sortDirection(model: string): string {
    const [, sortDirection] = (this.sortOrders[model] || 'name ASC').split(' ')

    return sortDirection
  }

  toggleMediaThumbnailView() {
    this.mediaViewStyle = this.isMediaThumbnailView() ? 'list' : 'thumbnails'
  }

  isMediaThumbnailView() {
    return this.mediaViewStyle === 'thumbnails'
  }
}

@Model()
export class Subtitle extends ApplicationRecord {
  static jsonapiType = 'subtitles'
  modelClassName = 'Subtitle'

  @BelongsTo() media!: Media
  @BelongsTo() dialect!: Dialect

  @Attr() data!: string
  @Attr() mediaId?: string
  @Attr() dialectId?: string
  @Attr() newUploadId?: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string
}

@Model()
export class CurrentUser extends ApplicationRecord {
  static jsonapiType = 'current_users'
  modelClassName = 'CurrentUser'

  @HasOne() preference!: Preference

  @Attr() email!: string
  @Attr() currentPassword!: string
  @Attr() password!: string
  @Attr() passwordConfirmation!: string

  @Attr({ persist: false }) customerId!: string
  @Attr({ persist: false }) customerName!: string
  @Attr({ persist: false }) login!: string
  @Attr({ persist: false }) name!: string
  @Attr({ persist: false }) lastLoginAt!: Date
  @Attr({ persist: false }) locale!: string
  @Attr({ persist: false }) currentSignInAt!: Date
  @Attr({ persist: false }) lastSignInAt!: Date
  @Attr({ persist: false }) currentSignInIp!: string
  @Attr({ persist: false }) lastSignInIp!: string
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedByName!: string
  @Attr({ persist: false }) customerIsExpired!: boolean

  // GUP Permissions
  @Attr({ persist: false }) gupViewPlayers!: boolean
  @Attr({ persist: false }) gupUpdatePlayers!: boolean
  @Attr({ persist: false }) gupDeletePlayers!: boolean
  @Attr({ persist: false }) gupViewMedia!: boolean
  @Attr({ persist: false }) gupUpdateMedia!: boolean
  @Attr({ persist: false }) gupDeleteMedia!: boolean
  @Attr({ persist: false }) granularPermissableObjectIdsAndTypes!: {
    id: string
    type: string
    name: string
    network_id: number
  }[]

  @Attr({ persist: false }) roleNames!: string[]
  @Attr() roleOrGranular!: 'role' | 'granular'

  @Attr({ persist: false }) isAdmin!: boolean
  @Attr({ persist: false }) isRdmSuperUser!: boolean
  @Attr({ persist: false }) isSuperUser!: boolean
}

@Model()
export class Log extends ApplicationRecord {
  static jsonapiType = 'logs'
  modelClassName = 'Log'

  @Attr({ persist: false }) downloadUrl?: string

  @Attr() event!: string
  @Attr() startPlayerLocaltime!: Date
  @Attr() playerLocaltime!: Date
  @Attr() fileName!: string
  @Attr() playlist!: string
  @Attr() displayState!: string
  @Attr() displayWeight!: number

  @Attr() playerId!: string
  @Attr() locationId!: string
  @Attr() networkId!: string
  @Attr() mediaId!: string

  @Attr() playerName!: string
  @Attr() locationName!: string

  @Attr() startHour!: number
  @Attr() startMinute!: number
  @Attr() startSecond!: number
  @Attr() startMonth!: number
  @Attr() startDay!: number
  @Attr() startYear!: number

  @Attr() endHour!: number
  @Attr() endMinute!: number
  @Attr() endSecond!: number
  @Attr() endMonth!: number
  @Attr() endDay!: number
  @Attr() endYear!: number

  @BelongsTo() player!: Player
  @BelongsTo() media!: Media

  getStart() {
    let date = new Date()

    date.setHours(this.startHour)
    date.setMinutes(this.startMinute)
    date.setSeconds(this.startSecond)

    date.setFullYear(this.startYear)
    date.setDate(this.startDay)
    date.setMonth(this.startMonth - 1)

    return date
  }

  getEnd() {
    let date = new Date()

    date.setHours(this.endHour)
    date.setMinutes(this.endMinute)
    date.setSeconds(this.endSecond)

    date.setFullYear(this.endYear)
    date.setDate(this.endDay)
    date.setMonth(this.endMonth - 1)

    return date
  }
}

@Model()
export class Media extends ApplicationRecord {
  static jsonapiType = 'media'
  modelClassName = 'Media'

  @HasMany() tags!: Tag[]
  @HasMany() subtitles!: Subtitle[]
  @HasMany() feedItems!: FeedItem[]
  @HasMany() customFieldValues!: CustomFieldValue[]
  @HasMany() spotSwapItems!: SpotSwapItem[]
  @HasMany() smartGroups!: SmartGroup[]
  @HasMany() mediaGroups!: MediaGroup[]

  @BelongsTo('media') linkedMedia!: Media
  @BelongsTo('media') feedBackgroundImage!: Media
  @Attr() feedBackgroundImageId!: string
  @BelongsTo('media') feedIcon!: Media
  @Attr() feedIconId!: string

  @Attr() removeDefaultFile!: boolean

  @Attr({ persist: false }) allPresentations!: Presentation[]
  @Attr({ persist: false }) aspectRatio!: string
  @Attr() audioCodec!: string
  @Attr() audioEncodeRateKilobitsPerSecond!: number
  @Attr() audioSampleRateKilobitsPerSecond!: number
  @Attr() backgroundImageFileName!: string
  @Attr() cachedFileSize!: number
  @Attr() cachedMd5sum!: string
  @Attr() cachingFrequency!: number
  @Attr() colorspace!: string
  @Attr() custom!: boolean
  @Attr() customerId!: string
  @Attr({ persist: false }) deletedAt!: Date
  @Attr() description!: string
  @Attr() displaySpeed!: number
  @Attr() displayStyle!: string
  @Attr() duration!: number
  @Attr() expired!: boolean
  @Attr() feedBackgroundColor!: string
  @Attr() feedFontId!: string
  @Attr() feedFontSize!: number
  @Attr() feedForegroundColor!: string
  @Attr() feedFrameRate!: number
  @Attr() feedHorizontalAlignment!: string
  @Attr() feedHorizontalPad!: string
  @Attr() feedItemsCount!: number
  @Attr() feedMessageScrollRate!: number
  @Attr() feedTickerType!: string
  @Attr() feedVerticalAlignment!: string
  @Attr() feedVerticalPad!: string
  @Attr() fileName!: string
  @Attr() height!: number
  @Attr() httpCallbackCompleteUrl!: string
  @Attr() httpCallbackCompleteVerb!: string
  @Attr() httpCallbackFailureUrl!: string
  @Attr() httpCallbackFailureVerb!: string
  @Attr() httpCallbackProgressUrl!: string
  @Attr() httpCallbackProgressVerb!: string
  @Attr() httpCallbackStartingUrl!: string
  @Attr() httpCallbackStartingVerb!: string
  @Attr({ persist: false }) isActive!: boolean
  @Attr() isDirectorSpot!: boolean
  @Attr() isSpotSwap!: boolean
  @Attr() isZipCodeEnabled!: boolean
  @Attr() lastCachedAt!: Date
  @Attr() linkedMediaId!: string
  @Attr() linkedMediaType!: string
  @Attr({ persist: false }) linkedMediaName!: string
  @Attr() localFeed!: boolean
  @Attr() logable!: boolean
  @Attr() mediaLinkId!: string
  @Attr({ persist: false }) noCdnMediaUrl!: string
  @Attr({ persist: false }) mediaUrl!: string
  @Attr() name!: string
  @Attr() needsPreviewGenerated!: boolean
  @Attr() needsThumbnailGenerated!: boolean
  @Attr() newFileName!: string
  @Attr() newFileId!: string
  @Attr() noLongerPlayableAt!: Date | undefined
  @Attr() playableAt!: Date | undefined
  @Attr({ persist: false }) previewUrl!: string
  @Attr() refreshRate!: number
  @Attr() remoteUrl!: string
  @Attr() rssIconFileName!: string
  @Attr() runTime!: number
  @Attr() runTimeMs!: number
  @Attr() screenPosition!: number
  @Attr() tagIds!: string[]
  @Attr() thumbnailFrameNumber!: number
  @Attr() thumbnailSpotSwapItemId!: string
  @Attr({ persist: false }) thumbnailUrl!: string
  @Attr() thumbnailSrc!: string
  @Attr() type!: MediaSubTypes
  @Attr({ persist: false }) used!: boolean
  @Attr() videoCodec!: string
  @Attr() videoEncodeRateMegabitsPerSecond!: number
  @Attr() videoFramesPerSecond!: number
  @Attr() volume!: number
  @Attr() webContentControlsDuration!: number
  @Attr() width!: number
  @Attr({ persist: false }) createdAt!: Date
  @Attr({ persist: false }) createdBy!: string
  @Attr({ persist: false }) createdByName!: string
  @Attr({ persist: false }) updatedAt!: Date
  @Attr({ persist: false }) updatedBy!: string
  @Attr({ persist: false }) updatedByName!: string
}

export const loginWithJWT = async (url: string, username: string, password: string) => {
  ApplicationRecord.baseUrl = url

  const user = {
    login: username,
    password,
  }

  const response = await fetch(`${url}/auth/login`, {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({ user }),
  })

  if (response.status === 200) {
    ApplicationRecord.jwt = response.headers.get('authorization') || undefined

    return true
  } else {
    return false
  }
}

@Model()
export class DuplicatePresentation extends ApplicationRecord {
  static jsonapiType = 'duplicate_presentations'
  modelClassName = 'DuplicatePresentation'

  @Attr() id?: string
  @Attr() name?: string
  @Attr() copyContent?: boolean
}

@Model()
export class DuplicateSmartGroup extends ApplicationRecord {
  static jsonapiType = 'duplicate_smart_groups'
  modelClassName = 'DuplicateSmartGroup'

  @BelongsTo() smartGroup!: SmartGroup
  @Attr() smartGroupId?: string
}

@Model()
export class DuplicateMediaGroup extends ApplicationRecord {
  static jsonapiType = 'duplicate_media_groups'
  modelClassName = 'DuplicateMediaGroup'

  @BelongsTo() mediaGroup!: MediaGroup
  @Attr() mediaGroupId?: string
}

@Model()
export class DuplicatePlayerGroup extends ApplicationRecord {
  static jsonapiType = 'duplicate_player_groups'
  modelClassName = 'DuplicatePlayerGroup'

  @BelongsTo() playerGroup!: PlayerGroup
  @Attr() playerGroupId?: string
}

@Model()
export class PlayerModelDefinition extends ApplicationRecord {
  static jsonapiType = 'player_model_definitions'
  modelClassName = 'PlayerModelDefinition'

  @Attr() modelIdentifier!: string
  @Attr() supportsScreenShot!: boolean
  @Attr() usesCallin!: boolean
  @Attr() hasAwcrpTimestampSupport!: boolean
  @Attr() hasScreenOrientationSupport!: boolean
  @Attr() hasDialup!: boolean
  @Attr() mediaPartitionName!: string
  @Attr() wirelessEncryptionModes!: string
  @Attr() createdAt!: string
  @Attr() updatedAt!: string
}

@Model()
export class PlayerSidebarCount extends ApplicationRecord {
  static jsonapiType = 'player_sidebar_counts'
  modelClassName = 'PlayerSidebarCounts'

  @Attr() networks!: number
  @Attr() locations!: number
  @Attr() players!: number
  @Attr() smartGroups!: number
  @Attr() playerGroups!: number
  @Attr() userFolders!: number
}

@Model()
export class PresentationSidebarCount extends ApplicationRecord {
  static jsonapiType = 'presentation_sidebar_counts'
  modelClassName = 'PresentationSidebarCounts'

  @Attr() smartGroups!: number
  @Attr() userFolders!: number
}

@Model()
export class UserSidebarCount extends ApplicationRecord {
  static jsonapiType = 'user_sidebar_counts'
  modelClassName = 'UserSidebarCounts'

  @Attr() adminUsers!: number
  @Attr() superUsers!: number
  @Attr() roleUsers!: number
  @Attr() granularUsers!: number
}

@Model()
export class LibrarySidebarCount extends ApplicationRecord {
  static jsonapiType = 'library_sidebar_counts'
  modelClassName = 'LibrarySidebarCounts'

  @Attr() audios!: number
  @Attr() bins!: number
  @Attr() feeds!: number
  @Attr() htmlPages!: number
  @Attr() images!: number
  @Attr() spotSwaps!: number
  @Attr() videos!: number
  @Attr() smartGroups!: number
  @Attr() mediaGroups!: number
  @Attr() userFolders!: number
}
